/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('pay-bills', {
      parent: 'root',
      url: '/pay-bills',
      template: '<ui-view />',
      redirectTo: '.default',
      data: {
        permissions: {
          only: 'payNearMeEligible',
          redirectTo: {
            payNearMeEligible: 'dashboard'
          }
        }
      },
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.section.pay-bills" */ 'sections/pay-bills').then(
            $ocLazyLoad.loadModule
          );
        },
        $client: /* @ngInject */ function(
          module,
          acpPayNearMeDomainModel,
          nsPermissions,
          NS_PROGRAM_TYPES
        ) {
          return nsPermissions
            .requestPermission('isHubUser')
            .then(function(isHub) {
              if (isHub) {
                return acpPayNearMeDomainModel.setClient(
                  NS_PROGRAM_TYPES.HUB_USER
                );
              } else {
                // doesn't matter that we set as GPR, as long as it's not HUB_USER,
                // acpPayNearMeDomainModel will use the correct client
                return acpPayNearMeDomainModel.setClient(NS_PROGRAM_TYPES.GPR);
              }
            });
        }
      }
    })
    .state('pay-bills.default', {
      controller: 'AcpPayBillsComponentCtrl',
      controllerAs: 'vm',
      templateUrl:
        'sections/pay-bills/templates/acp-payment-pay-near-me-echeck-page.ng.html',
      data: {
        permissions: {
          except: 'hub_user',
          redirectTo: {
            hub_user: 'pay-bills.hub'
          }
        }
      },
      // We use this as a hook for sending additional GA info
      onEnter: /* @ngInject */ function(
        module,
        acpPayNearMeAnalyticsModel,
        PermTransitionProperties
      ) {
        if (PermTransitionProperties.options.fromSidenav) {
          acpPayNearMeAnalyticsModel.sendClickedPayBillsEvent();
        }
      }
    })
    .state('pay-bills.hub', {
      templateUrl:
        'sections/pay-bills/templates/acp-payment-pay-near-me-echeck-page.ng.html',
      data: {
        permissions: {
          only: 'hub_user',
          redirectTo: {
            hub_user: 'pay-bills.default'
          }
        }
      },
      onEnter: /* @ngInject */ function(module, acpPayNearMeAnalyticsModel) {
        acpPayNearMeAnalyticsModel.sendClickedHubPayBillsEvent();
      }
    });
}

export default states;
